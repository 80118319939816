import React, { useContext, useEffect, useMemo, useRef } from 'react';
import _ from 'lodash';

import Layout from 'ecto-common/lib/Layout/Layout';
import ContentArea from 'ecto-common/lib/Layout/ContentArea/ContentArea';
import ToastContainer from 'ecto-common/lib/Toast/ToastContainer';
import BaseContainer from 'ecto-common/lib/BaseContainer/BaseContainer';
import { toastStore } from 'ecto-common/lib/Toast/ToastContainer';
import {
  REQ_STATE_ERROR,
  REQ_STATE_PENDING,
  REQ_STATE_SUCCESS,
  RequestStatusRawProp
} from 'ecto-common/lib/utils/requestStatus';
import T from 'ecto-common/lib/lang/Language';
import { AuthenticationErrorComponent } from 'ecto-common/lib/AuthenticationWrapper/AuthenticationWrapper';

import { setEquipmentGroupTemplates } from 'js/actions/getEquipmentGroupTemplates';

import { setEnums } from 'ecto-common/lib/actions/getEnums';

import styles from './AdminContainer.module.css';
import useAuthentication from 'ecto-common/lib/hooks/useAuthentication';
import { setSignalTypes } from 'ecto-common/lib/actions/setSignalTypes';
import { setSignalTypeFolders } from 'ecto-common/lib/actions/setSignalTypeFolders';
import LoadingScreenWithMenu from 'ecto-common/lib/LoadingScreen/LoadingScreenWithMenu';
import TenantContext from 'ecto-common/lib/hooks/TenantContext';
import UserContext from 'ecto-common/lib/hooks/UserContext';
import { setEquipmentTypes } from 'ecto-common/lib/actions/getEquipmentTypes';
import { setNodes, useNodeCacheUpdate } from 'ecto-common/lib/actions/getNodes';
import { setNodeTags } from 'ecto-common/lib/actions/getNodeTags';
import { setSignalTemplates } from 'js/actions/getSignalTemplates';
import { useAdminSelector } from 'js/reducers/storeAdmin';
import { CACHE_KEY_NODES } from 'ecto-common/lib/utils/cacheKeys';
import { hasAccessToResource } from 'ecto-common/lib/utils/accessAndRolesUtil';
import { ResourceType } from 'ecto-common/lib/constants/index';
import { getApiEnvironment } from 'ecto-common/lib/utils/apiEnvironment';
import { ApiContextSettings } from 'ecto-common/lib/API/APIUtils';
import { AuthError } from '@azure/msal-browser';
import { useInitialUserSettings } from 'ecto-common/lib/Application/useInitialUserSettings';
import { Outlet } from 'react-router-dom';
import APIGen, {
  GridType,
  NodeResponseModel
} from 'ecto-common/lib/API/APIGen';
import {
  useCommonDispatch,
  useCommonSelector
} from 'ecto-common/lib/reducers/storeCommon';
import { useQuery } from '@tanstack/react-query';
import localStore from 'store';

const getSignalTemplatesPromise = (
  contextSettings: ApiContextSettings,
  signal: AbortSignal
) => {
  return Promise.all([
    APIGen.AdminAlarms.getAllAlarmSignalGroupTemplates.promise(
      contextSettings,
      signal
    ),
    APIGen.AdminEquipments.getEquipmentSignalProviderTemplates.promise(
      contextSettings,
      {},
      signal
    )
  ] as const);
};

export function getNodesPromise(
  contextSettings: ApiContextSettings,
  signal: AbortSignal
) {
  return Promise.all([
    APIGen.AdminNodes.getNodes.promise(contextSettings, {}, signal),
    APIGen.Nodes.getGrids.promise(contextSettings, signal)
  ] as const);
}

const CoreResourceWrapper = React.memo(() => {
  const apiEnvironment = getApiEnvironment();

  const nodeTreeLoaded = useCommonSelector(
    (state) => state.general.nodeTreeLoaded
  );

  const { userId } = useContext(UserContext);
  const { isAuthenticated, instance, currentAccount, isLoggingOut } =
    useAuthentication(apiEnvironment.scopes.gateway);
  const { contextSettings, tenantId } = useContext(TenantContext);

  useNodeCacheUpdate();

  const queryOptions = {
    enabled: userId != null && tenantId != null,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false
  };

  const dispatch = useCommonDispatch();

  const enumsQuery = useQuery({
    queryKey: APIGen.Enums.getEnumsAndFixedConfigurations.path(contextSettings),

    queryFn: ({ signal }) => {
      return APIGen.Enums.getEnumsAndFixedConfigurations
        .promise(contextSettings, signal)
        .then((result) => {
          dispatch(setEnums(result));
          return result;
        });
    },
    ...queryOptions
  });

  const signalTemplatesQuery = useQuery({
    queryKey: ['allSignalTemplates', tenantId],

    queryFn: ({ signal }) => {
      return getSignalTemplatesPromise(contextSettings, signal).then(
        (result) => {
          dispatch(setSignalTemplates(result));
          return result;
        }
      );
    },
    ...queryOptions
  });

  const nodeTagsQuery = useQuery({
    queryKey: APIGen.AdminNodes.getNodeTags.path(contextSettings),

    queryFn: ({ signal }) =>
      APIGen.AdminNodes.getNodeTags
        .promise(contextSettings, signal)
        .then((result) => {
          dispatch(setNodeTags(result));
          return result;
        }),
    ...queryOptions
  });

  const equipmentGroupsQuery = useQuery({
    queryKey: APIGen.AdminBuildings.getBuildingTemplates.path(contextSettings),

    queryFn: ({ signal }) =>
      APIGen.AdminBuildings.getBuildingTemplates
        .promise(contextSettings, signal)
        .then((result) => {
          dispatch(setEquipmentGroupTemplates(result));
          return result;
        }),
    ...queryOptions
  });

  const equipmentTypesQuery = useQuery({
    queryKey: APIGen.AdminEquipments.getEquipmentTypes.path(contextSettings),

    queryFn: ({ signal }) =>
      APIGen.AdminEquipments.getEquipmentTypes
        .promise(contextSettings, signal)
        .then((result) => {
          dispatch(setEquipmentTypes(result));
          return result;
        }),
    ...queryOptions
  });

  const signalTypesQuery = useQuery({
    queryKey: APIGen.AdminSignalTypes.getAllSignalTypes.path(contextSettings),

    queryFn: ({ signal }) =>
      APIGen.AdminSignalTypes.getAllSignalTypes
        .promise(contextSettings, signal)
        .then((result) => {
          dispatch(setSignalTypes(result));
          return result;
        }),
    ...queryOptions
  });

  const signalTypeFoldersQuery = useQuery({
    queryKey:
      APIGen.AdminSignalTypeFolders.getAllSignalTypeFolders.path(
        contextSettings
      ),

    queryFn: ({ signal }) =>
      APIGen.AdminSignalTypeFolders.getAllSignalTypeFolders
        .promise(contextSettings, signal)
        .then((result) => {
          dispatch(setSignalTypeFolders(result));
          return result;
        }),
    ...queryOptions
  });

  const nodesQuery = useQuery({
    queryKey: ['getNodes', tenantId],

    queryFn: ({ signal }) => {
      return getNodesPromise(contextSettings, signal).then((result) => {
        dispatch(setNodes(result as [NodeResponseModel[], GridType[]]));
        const cacheKey = CACHE_KEY_NODES + '-' + tenantId;

        try {
          localStore.set(cacheKey, JSON.stringify(result));
        } catch (e) {
          // Silently fail. Result will still be returned, just not cached.
          // Can be because of local storage limits etc.
          console.error(e);
        }

        return Promise.resolve(result);
      });
    },
    ...queryOptions
  });

  const hasError =
    enumsQuery.isError ||
    signalTemplatesQuery.isError ||
    nodeTagsQuery.isError ||
    equipmentGroupsQuery.isError ||
    equipmentTypesQuery.isError ||
    signalTypesQuery.isError ||
    signalTypeFoldersQuery.isError ||
    nodesQuery.isError;

  const attemptedLoadNodesRef = useRef<string>(null);
  const cacheKey = CACHE_KEY_NODES + '-' + tenantId;

  useEffect(() => {
    if (nodesQuery.data == null && attemptedLoadNodesRef.current == null) {
      attemptedLoadNodesRef.current = cacheKey;
      const cachedEntry = localStore.get(cacheKey);

      if (cachedEntry) {
        dispatch(setNodes(JSON.parse(cachedEntry)));
      }
    }
  }, [cacheKey, dispatch, nodesQuery.data]);

  const nodesDoneLoading = nodesQuery.isError || nodeTreeLoaded;

  useEffect(() => {
    if (hasError && isAuthenticated) {
      toastStore.addErrorToast(T.common.baserequesterror);
    }
  }, [hasError, isAuthenticated]);

  const isLoading =
    nodeTagsQuery.isLoading ||
    equipmentTypesQuery.isLoading ||
    !nodesDoneLoading ||
    enumsQuery.isLoading ||
    signalTypeFoldersQuery.isLoading ||
    signalTypesQuery.isLoading;

  if (isLoading) {
    return <LoadingScreenWithMenu isLoading />;
  } else if (currentAccount && !isLoggingOut) {
    return (
      <BaseContainer
        msalConfiguration={instance}
        currentAccount={currentAccount}
      >
        <Outlet />
      </BaseContainer>
    );
  }

  return null;
});

const Container = React.memo(() => {
  const apiEnvironment = getApiEnvironment();
  const {
    isLoading: authenticationIsLoading,
    errorMessage,
    instance,
    currentAccount,
    isLoggingOut
  } = useAuthentication(apiEnvironment.scopes.gateway);

  const userSettingsQuery = useInitialUserSettings(
    !!currentAccount && !errorMessage
  );

  const { isLoadingTenants, tenantsFailedToLoad, tenantResources } =
    useContext(TenantContext);
  const isLoading =
    authenticationIsLoading || isLoadingTenants || userSettingsQuery.isLoading;
  let _errorMessage = errorMessage;

  if (tenantsFailedToLoad) {
    _errorMessage = AuthError.createUnexpectedError(
      T.tenants.error.failedtoload
    );
  }

  let content: React.ReactNode = null;
  if (isLoading) {
    content = <LoadingScreenWithMenu isLoading />;
  } else if (hasAccessToResource(ResourceType.CORE, tenantResources)) {
    content = <CoreResourceWrapper />;
  } else if (currentAccount && !isLoggingOut) {
    content = (
      <BaseContainer
        msalConfiguration={instance}
        currentAccount={currentAccount}
      >
        <Outlet />
      </BaseContainer>
    );
  }

  return (
    <>
      {!_errorMessage ? (
        <div className={styles.baseContainer}>{content}</div>
      ) : (
        <AuthenticationErrorComponent error={_errorMessage} />
      )}
    </>
  );
});

const MainContentArea = React.memo(() => {
  const { tenantId } = useContext(TenantContext);

  return (
    <ContentArea>
      <Container key={tenantId} />
      <ToastContainer />
    </ContentArea>
  );
});

const useReqStateToast = () => {
  const reqState = useAdminSelector((state) => state.reqState);
  const prevReqState = useRef(_.cloneDeep(reqState));

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const nextReqState: any = reqState;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const _prevReqState: any = prevReqState.current;

    Object.keys(nextReqState).forEach((key) => {
      if (
        nextReqState[key].statusText &&
        nextReqState[key].statusText !== _prevReqState[key].statusText
      ) {
        if (nextReqState[key].state === REQ_STATE_SUCCESS) {
          toastStore.addSuccessToast(nextReqState[key].statusText);
        } else if (nextReqState[key].state === REQ_STATE_ERROR) {
          toastStore.addErrorToast(nextReqState[key].statusText);
        }
      }
    });
    prevReqState.current = _.cloneDeep(reqState);
  }, [reqState]);

  return useMemo(() => {
    return (
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      Object.values(reqState as unknown as RequestStatusRawProp<any>[]).filter(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (request: RequestStatusRawProp<any>) =>
          request.state === REQ_STATE_PENDING && request.blocking
      ).length !== 0
    );
  }, [reqState]);
};

const AdminContainer = () => {
  const isBlocking = useReqStateToast();
  return <Layout disabled={isBlocking} contentArea={<MainContentArea />} />;
};

export default React.memo(AdminContainer);
