import React, {
  ChangeEventHandler,
  memo,
  useCallback,
  useContext,
  useEffect,
  useState
} from 'react';
import _ from 'lodash';

import ModalBody from 'ecto-common/lib/Modal/ModalBody';
import ModalFooter from 'ecto-common/lib/Modal/ModalFooter';
import ModalSpace from 'ecto-common/lib/Modal/ModalSpace';
import DeleteButton from 'ecto-common/lib/Button/DeleteButton';
import LocalizedButtons from 'ecto-common/lib/Button/LocalizedButtons';
import SaveButton from 'ecto-common/lib/Button/SaveButton';
import { KeyValueLine } from 'ecto-common/lib/KeyValueInput/KeyValueLine';
import { KeyValueInput } from 'ecto-common/lib/KeyValueInput/KeyValueInput';
import { toastStore } from 'ecto-common/lib/Toast/ToastContainer';
import ConfirmDeleteDialog from 'ecto-common/lib/ConfirmDeleteDialog/ConfirmDeleteDialog';
import T from 'ecto-common/lib/lang/Language';

import { ApiContextSettings } from 'ecto-common/lib/API/APIUtils';
import { Base64 } from 'js-base64';
import { emptyProcessMapDocument } from 'ecto-common/lib/ProcessMap/ProcessMapViewConstants';
import APIGen, {
  AddOrUpdateProcessMapRequestModel,
  ProcessMapResponseModel,
  ProcessMapRevisionResponseModel
} from 'ecto-common/lib/API/APIGen';
import ProcessMapTable from 'ecto-common/lib/ProcessMaps/ProcessMapTable';
import { useMutation } from '@tanstack/react-query';
import TenantContext from 'ecto-common/lib/hooks/TenantContext';

const updateProcessMap = (
  contextSettings: ApiContextSettings,
  { id, name, description }: AddOrUpdateProcessMapRequestModel
) => {
  return APIGen.AdminProcessMaps.addOrUpdateProcessMaps.promise(
    contextSettings,
    [
      {
        id,
        name,
        description
      }
    ],
    null
  );
};

const createProcessMap = (
  contextSettings: ApiContextSettings,
  { name, description }: AddOrUpdateProcessMapRequestModel,
  selectedProcessMapTemplateId: string
) => {
  let revisionDataRequest = Promise.resolve(
    Base64.encode(JSON.stringify(emptyProcessMapDocument))
  );

  if (selectedProcessMapTemplateId != null) {
    revisionDataRequest =
      APIGen.AdminProcessMaps.getProcessMapRevisionsByProcessMapId
        .promise(
          contextSettings,
          { processMapIds: [selectedProcessMapTemplateId] },
          null
        )
        .then((results) => {
          const lastRevision = _.head(results);
          return APIGen.AdminProcessMaps.getProcessMapRevisionsById.promise(
            contextSettings,
            { ids: [lastRevision.id] },
            null
          );
        })
        .then((result) => {
          return result[0].map;
        });
  }

  return Promise.all([
    APIGen.AdminProcessMaps.addOrUpdateProcessMaps.promise(
      contextSettings,
      [
        {
          name,
          description
        }
      ],
      null
    ),
    revisionDataRequest
  ]).then((promises) => {
    const [data, initialMap] = promises;

    return APIGen.AdminProcessMaps.addOrUpdateProcessMapRevisions.promise(
      contextSettings,
      [
        {
          processMapId: data[0].id,
          map: initialMap,
          comment: 'Initial version'
        }
      ],
      null
    );
  });
};

interface ProcessMapsEditModalContentProps {
  isOpen: boolean;
  onModalClose: () => void;
  isEditing: boolean;
  selectedProcessMapId?: string;
  items: Record<string, ProcessMapResponseModel>;
  reloadProcessMaps(newProcessMapId?: string): void;
}

const ProcessMapsEditModalContent = ({
  onModalClose,
  isEditing,
  selectedProcessMapId,
  items,
  reloadProcessMaps,
  isOpen
}: ProcessMapsEditModalContentProps) => {
  const processMap = _.get(items, selectedProcessMapId);
  const [name, setName] = useState(_.get(processMap, 'name', ''));
  const [description, setDescription] = useState(
    _.get(processMap, 'description', '')
  );

  const [selectedProcessMapTemplateId, setSelectedProcessMapTemplateId] =
    useState<string>(null);

  useEffect(() => {
    if (!isOpen && selectedProcessMapTemplateId != null) {
      setSelectedProcessMapTemplateId(null);
    }
  }, [isOpen, selectedProcessMapTemplateId]);

  const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);

  const successToastAndClose = useCallback(
    (message: string) => {
      toastStore.addSuccessToast(message);
      setDeleteModalIsOpen(false);
      onModalClose();
    },
    [onModalClose]
  );

  const updateMutation = useMutation({
    mutationFn: (args: AddOrUpdateProcessMapRequestModel) => {
      return updateProcessMap(contextSettings, args);
    },

    onSuccess: () => {
      successToastAndClose(T.admin.processmaps.updated);
      reloadProcessMaps?.(null);
    },

    onError: () => {
      toastStore.addErrorToast(T.admin.processmaps.error.update);
    }
  });

  const { contextSettings } = useContext(TenantContext);

  const createMutation = useMutation({
    mutationFn: (args: AddOrUpdateProcessMapRequestModel) => {
      return createProcessMap(
        contextSettings,
        args,
        selectedProcessMapTemplateId
      );
    },

    onSuccess: (result: ProcessMapRevisionResponseModel[]) => {
      successToastAndClose(T.admin.processmaps.created);
      reloadProcessMaps?.(result[0].processMapId);
    },

    onError: () => {
      toastStore.addErrorToast(T.admin.processmaps.error.create);
    }
  });

  const deleteMutation = APIGen.AdminProcessMaps.deleteProcessMaps.useMutation({
    onSuccess: () => {
      successToastAndClose(T.admin.processmaps.removed);
      reloadProcessMaps?.(null);
    },
    onError: () => {
      toastStore.addErrorToast(T.admin.processmaps.error.delete);
    }
  });

  const isLoading =
    updateMutation.isPending ||
    createMutation.isPending ||
    deleteMutation.isPending;

  const onSaveHandler = useCallback(() => {
    if (name.length <= 0) {
      toastStore.addErrorToast(T.admin.processmaps.error.missingname);
      return;
    }

    if (isEditing) {
      updateMutation.mutate({ id: selectedProcessMapId, name, description });
    } else {
      createMutation.mutate({ name, description });
    }
  }, [
    name,
    isEditing,
    updateMutation,
    selectedProcessMapId,
    description,
    createMutation
  ]);

  const onDeleteHandler = useCallback(() => {
    deleteMutation.mutate({
      ids: [selectedProcessMapId]
    });
  }, [deleteMutation, selectedProcessMapId]);

  const onChangeName: ChangeEventHandler<HTMLInputElement> = useCallback(
    (event) => setName(event.target.value),
    []
  );

  const onChangeDescription: ChangeEventHandler<HTMLInputElement> = useCallback(
    (event) => setDescription(event.target.value),
    []
  );

  const onClickProcessMap = useCallback(
    (templateProcessMap: ProcessMapResponseModel) => {
      if (templateProcessMap.id === selectedProcessMapTemplateId) {
        setSelectedProcessMapTemplateId(null);
      } else {
        setSelectedProcessMapTemplateId(templateProcessMap.id);
      }
    },
    [selectedProcessMapTemplateId]
  );

  const onDeleteButtonClick = useCallback(() => {
    setDeleteModalIsOpen(true);
  }, []);

  return (
    <>
      <ModalBody loading={isLoading}>
        <KeyValueLine>
          <KeyValueInput
            disabled={isLoading}
            keyText={T.admin.processmaps.name}
            value={name}
            onChange={onChangeName}
          />
        </KeyValueLine>

        <KeyValueLine>
          <KeyValueInput
            disabled={isLoading}
            keyText={T.admin.processmaps.description}
            value={description}
            onChange={onChangeDescription}
          />
        </KeyValueLine>

        {!isEditing && (
          <ProcessMapTable
            items={items}
            onClickRow={onClickProcessMap}
            showCheckboxes
            selectedId={selectedProcessMapTemplateId}
            nameColumnTitle={T.admin.processmaps.templatename}
          />
        )}
      </ModalBody>

      <ModalFooter>
        {isEditing && (
          <DeleteButton disabled={isLoading} onClick={onDeleteButtonClick}>
            {T.admin.processmaps.deletemap}
          </DeleteButton>
        )}

        <ModalSpace />

        <SaveButton disabled={isLoading} onClick={onSaveHandler}>
          {isEditing ? T.common.save : T.admin.processmaps.add}
        </SaveButton>

        <LocalizedButtons.Cancel disabled={isLoading} onClick={onModalClose} />
      </ModalFooter>

      <ConfirmDeleteDialog
        isOpen={deleteModalIsOpen}
        isLoading={deleteMutation.isPending}
        onDelete={onDeleteHandler}
        onModalClose={() => setDeleteModalIsOpen(false)}
        itemName={name}
      />
    </>
  );
};

export default memo(ProcessMapsEditModalContent);
