import React, { useCallback, useContext, useEffect } from 'react';

import { useNavigate } from 'react-router-dom';
import { getEquipmentName } from 'ecto-common/lib/utils/equipmentTypeUtils';
import Icons from 'ecto-common/lib/Icons/Icons';
import T from 'ecto-common/lib/lang/Language';
import DataTable, {
  DataTableColumnProps
} from 'ecto-common/lib/DataTable/DataTable';
import ToolbarContentPage from 'ecto-common/lib/ToolbarContentPage/ToolbarContentPage';
import useDialogState from 'ecto-common/lib/hooks/useDialogState';

import { toastStore } from 'ecto-common/lib/Toast/ToastContainer';
import HelpPaths from 'ecto-common/help/tocKeys';

import AddEquipmentTypeDialog from 'js/components/ManageTemplates/EquipmentSignalTemplates/AddEquipmentTypeDialog';
import TenantContext from 'ecto-common/lib/hooks/TenantContext';
import { setEquipmentTypes } from 'ecto-common/lib/actions/getEquipmentTypes';
import { useAdminSelector, useAdminDispatch } from 'js/reducers/storeAdmin';
import APIGen, {
  AddOrUpdateEquipmentTypeRequestModel,
  EquipmentTypeResponseModel
} from 'ecto-common/lib/API/APIGen';

interface EquipmentTypeListItemProps {
  equipmentTypeId?: string;
}

const EquipmentTypeListItem = ({
  equipmentTypeId
}: EquipmentTypeListItemProps) => {
  const equipmentTypes = useAdminSelector(
    (state) => state.general.equipmentTypes
  );

  return (
    <>
      <Icons.Equipment /> {getEquipmentName(equipmentTypeId, equipmentTypes)}{' '}
    </>
  );
};

const columns: DataTableColumnProps<EquipmentTypeResponseModel>[] = [
  {
    label: null,
    dataKey: 'equipmentTypeId',
    flexGrow: 1,
    width: 100,
    linkColumn: true,
    dataFormatter: (value: string) => (
      <EquipmentTypeListItem equipmentTypeId={value} />
    )
  }
];

const EquipmentTypes = () => {
  const [showingDialog, showDialog, hideDialog] = useDialogState(
    'show-add-equipment-type-dialog'
  );
  const dispatch = useAdminDispatch();
  const equipmentTypes = useAdminSelector(
    (state) => state.general.equipmentTypes
  );
  const navigate = useNavigate();
  const { tenantId } = useContext(TenantContext);

  const createMutation =
    APIGen.AdminEquipments.addOrUpdateEquipmentTypes.useMutation({
      onSuccess: (updatedEquipmentTypes) => {
        hideDialog();
        dispatch(
          setEquipmentTypes([...equipmentTypes, ...updatedEquipmentTypes])
        );
        navigate(
          `/${tenantId}/templateManagement/equipments/${updatedEquipmentTypes[0].equipmentTypeId}`
        );
        toastStore.addSuccessToast(T.admin.requests.addequipmenttype.success);
      },
      onError: () => {
        toastStore.addErrorToast(T.admin.requests.addequipmenttype.failure);
      }
    });

  const onClickRow = useCallback(
    (equipment: EquipmentTypeResponseModel) => {
      navigate(
        `/${tenantId}/templateManagement/equipments/${equipment.equipmentTypeId}`
      );
    },
    [navigate, tenantId]
  );

  const onAddEquipmentType = useCallback(
    (newType: AddOrUpdateEquipmentTypeRequestModel) => {
      createMutation.mutate([newType]);
    },
    [createMutation]
  );

  useEffect(() => {
    document.title = T.admin.equipmenttypes.title;
  }, []);

  return (
    <ToolbarContentPage
      showLocationPicker={false}
      wrapContent={false}
      title={T.admin.equipmenttypes.title}
      addActionTitle={T.admin.equipmenttypes.add}
      addAction={showDialog}
      helpPath={HelpPaths.docs.admin.templates.equipment_types}
    >
      <AddEquipmentTypeDialog
        isLoading={createMutation.isPending}
        isOpen={showingDialog}
        onAddEquipmentType={onAddEquipmentType}
        onModalClose={hideDialog}
      />
      <DataTable<EquipmentTypeResponseModel>
        isLoading={false}
        disableHeader
        onClickRow={onClickRow}
        data={equipmentTypes}
        columns={columns}
      />
    </ToolbarContentPage>
  );
};

export default React.memo(EquipmentTypes);
